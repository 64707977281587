body {
	font-family: 'Nunito', sans-serif;
}

.wrapper {
	max-width: 1800px !important;
	margin: 0 auto;
}

.work-bg:hover {
	background-color: rgba($color: #000000, $alpha: 0.45);
}

.coder-svg {
	width: 325px;
	bottom: 1%;
	right: 5%;
	// 	@media screen and (min-width: 1050px) and (max-width: 1250px) {
	// 		width: 500px;
	// 	}
	// 	@media screen and (min-width: 950px) and (max-width: 1050px) {
	// 		width: 425px;
	// 	}
	@media screen and (max-width: 584px) {
		display: none;
		width: 0;
	}
}

.codewar-banner {
	width: 300px;
	@media all and (max-width: 280px) {
		width: 250px;
	}
}

.timeline::after {
	content: '';
	position: absolute;
	width: 2px;
	background-color: #63b3ed;
	top: 0;
	bottom: 0;
	left: 0%;
}

.language {
	width: 96%;
	svg {
		width: 48px !important;
		height: 48px !important;
	}
	@media all and (min-width: 545px) {
		width: 45%;
	}
	@media all and (min-width: 768px) {
		width: 30%;
		svg {
			width: 128px !important;
			height: 128px !important;
		}
	}
	@media all and (min-width: 1050px) {
		width: 22.5%;
	}
	@media all and (min-width: 1280px) {
		width: 18.5%;
	}
}

.work-card {
	width: 90%;
	@media all and (min-width: 400px) {
		width: 80%;
	}
	@media all and (min-width: 600px) {
		width: 70%;
	}
	@media all and (min-width: 768px) {
		width: 45%;
	}
	@media all and (min-width: 1050px) {
		width: 42.5%;
	}
	@media all and (min-width: 1280px) {
		width: 30%;
	}
}

.tab {
	font-size: 1rem;
	margin-right: 10px;
	// @media all and (min-width: 280px) {
	// 	font-size: 0.2rem;
	// }
	// @media all and (min-width: 360px) {
	// 	font-size: 0.35rem;
	// }
	// @media all and (min-width: 545px) {
	// 	font-size: 0.75rem;
	// }
	// @media all and (min-width: 768px) {
	// 	font-size: 0.85rem;
	// }
	// @media all and (min-width: 1050px) {
	// 	font-size: 1rem;
	// }
}

.loading {
	.three {
		position: relative;
		height: 100px;
		width: 100px;
		border-radius: 50%;
		border: solid transparent 10px;
		border-top: solid #333 10px;
		border-left: solid #333 10px;
		animation: loader3Animation 2s linear infinite;
		margin-left: auto;
		margin-right: auto;
		margin-top: 15px;
	}

	.three:before {
		position: absolute;
		top: 20px;
		left: 20px;
		content: '';
		width: 40px;
		height: 40px;
		border: solid transparent 10px;
		border-top: solid #333 10px;
		border-left: solid #333 10px;
		border-radius: 50%;
		animation: loader3AnimationBefore 1.5s linear infinite;
	}

	.three:after {
		position: absolute;
		top: 5px;
		left: 5px;
		content: '';
		width: 70px;
		height: 70px;
		border: solid transparent 10px;
		border-top: solid #333 10px;
		border-left: solid #333 10px;
		border-radius: 50%;
		animation: loader3AnimationAfter 1.5s linear infinite;
	}

	@keyframes loader3Animation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes loader3AnimationBefore {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes loader3AnimationAfter {
		0% {
			transform: rotate(360deg);
		}
		100% {
			transform: rotate(-360deg);
		}
	}
}

.exp-desc {
	list-style-type: disc;
	margin-left: 13px;
}

.nav-menu {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.footer-text {
	position: absolute;
	margin-left: 100px;
	color: #0780d8;
}

#logo-text {
	color: #3a2585;
	font-style: italic;
	background: rgba(255, 215, 0, 0.5);
	padding: 5px 15px 5px 10px;
}

.color-blue,
.quote {
	color: #0780d8;
	font-weight: 900;
}

.hero-iframe-img {
	// TODO fix this for One plus devices
	// @media screen and (max-width: 364px) {
	// 	display: none;
	// 	width: 0;
	// }

	// @media screen and (min-width: 365px) and (max-width: 584px) {
	// 	top: -250px;
	// 	// right: 210px;
	// 	position: absolute;
	// }

	@media screen and (min-width: 585px) and (max-width: 1089px) {
		top: -250px;
		right: 210px;
		position: absolute;
	}

	@media screen and (min-width: 1066px) {
		top: -450px;
		right: 50px;
		position: absolute;
		transform: scale3d(1.25, 1.25, 1.5);
	}
}
